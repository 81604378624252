import '../stylesheets/application.scss'
// eslint-disable-next-line no-undef
require.context('../images', true)

import * as UJS from '@rails/ujs'
import Turbolinks from 'turbolinks'
import * as ActiveStorage from '@rails/activestorage'

UJS.start()
Turbolinks.start()
ActiveStorage.start()
